import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';



export function PublicRoute({ component: Component, title, path, ...rest }) {

    const history = useHistory()

    const getParamsToObject = (params = window.location.search) => {
        let result = {};

        params.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => { return result[key] = value; });

        return result;
    }

    useEffect(() => {
        if (title)
            document.title = title
    }, [title])

    return (
        <Route
            {...rest}
            path={path}
            render={(props) => <Component
                {...rest}
                {...props}
                next={(pathname) => history.push(pathname + props.location.search)}
                params={getParamsToObject(props.location.search)}
            />}
        />
    )
}

